<template>
<div class="UnauthorizedContainer"></div>
<h2 class="UnauthorizedText">404<br> UnAuthorized</h2>
<br>
<h1 class="UnauthorizedTexth1">please contact your Administrator for more information </h1>
</template>

<script>

</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Audiowide&display=swap');


.UnauthorizedContainer{
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  margin: 0px;
  background: radial-gradient(circle, #3b3b3b 0%, #000000 100%);
  overflow: hidden;
}

.wrap{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.UnauthorizedText{
  position: absolute;
  top: 40%;
  left: 50%;
  font-size: 150px;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  display: block;
  color: #ffffff;
  font-weight: 300;
  font-family: Audiowide;
  text-shadow: 0px 0px 4px #dcdcdc;
  animation: fadeInText 3s ease-in 3.5s forwards, flicker4 5s linear 7.5s infinite, hueRotate 6s ease-in-out 3s infinite;
}

.UnauthorizedTexth1{
  position: absolute;
  top: 70%;
  left: 50%;
  font-size: 20px;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  display: block;
  color: #ffffff;
  font-weight: 300;
  font-family: Audiowide;
  text-shadow: 0px 0px 4px #dcdcdc;
  animation: fadeInText 3s ease-in 3.5s forwards, flicker4 5s linear 7.5s infinite, hueRotate 6s ease-in-out 3s infinite;
}
/* 
#svgWrap_1,
#svgWrap_2{
  position: absolute;
  height: auto;
  width: 600px;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#svgWrap_1,
#svgWrap_2,
.UnauthorizedContainer{
  animation: hueRotate 6s ease-in-out 3s infinite;
}

#id1_1,
#id2_1,
#id3_1{
  stroke: #ffdd00;
  stroke-width: 3px;
  fill: transparent;
  filter: url(#glow);
}

#id1_2,
#id2_2,
#id3_2{
  stroke: #120c00;
  stroke-width: 3px;
  fill: transparent;
  filter: url(#glow);
}

#id3_1{
  stroke-dasharray: 940px;
  stroke-dashoffset: -940px;
  animation: drawLine3 2.5s ease-in-out 0s forwards, flicker3 4s linear 4s infinite;
}

#id2_1{
  stroke-dasharray: 735px;
  stroke-dashoffset: -735px;
  animation: drawLine2 2.5s ease-in-out 0.5s forwards, flicker2 4s linear 4.5s infinite;
}

#id1_1{
  stroke-dasharray: 940px;
  stroke-dashoffset: -940px;
  animation: drawLine1 2.5s ease-in-out 1s forwards, flicker1 4s linear 5s infinite;
}

@keyframes drawLine1 {
  0%  {stroke-dashoffset: -940px;}
  100%{stroke-dashoffset: 0px;}
}

@keyframes drawLine2 {
  0%  {stroke-dashoffset: -735px;}
  100%{stroke-dashoffset: 0px;}
}

@keyframes drawLine3 {
  0%  {stroke-dashoffset: -940px;}
  100%{stroke-dashoffset: 0px;}
}

@keyframes flicker1{
  0%  {stroke: #ffb700;}
  1%  {stroke: #ffffff;}
  3%  {stroke: #ffffff;}
  4%  {stroke: #ffb700;}
  6%  {stroke: #ffb700;}
  7%  {stroke: #ffffff;}
  13% {stroke: #ffffff;}
  14% {stroke: #ffb700;}
  100%{stroke: #ffb700;}
}

@keyframes flicker2{
  0%  {stroke: #ffea00;}
  50% {stroke: #ffea00;}
  51% {stroke: #ffffff;}
  61% {stroke: #ffffff;}
  62% {stroke: #ffea00;}
  100%{stroke: #ffea00;}
}

@keyframes flicker3{
  0%  {stroke: #ffb700;}
  1%  {stroke: #ffffff;}
  10% {stroke: #ffffff;}
  11% {stroke: #ffb700;}
  40% {stroke: #ffb700;}
  41% {stroke: #ffffff;}
  45% {stroke: #ffffff;}
  46% {stroke: #ffb700;}
  100%{stroke: #ffb700;}
}

@keyframes flicker4{
  0%  {color: #ffb700;text-shadow:0px 0px 4px #ffb700;}
  30% {color: #ffb700;text-shadow:0px 0px 4px #ffb700;}
  31% {color: #120c00;text-shadow:0px 0px 4px #120c00;}
  32% {color: #ffea00;text-shadow:0px 0px 4px #ffea00;}
  36% {color: #ffea00;text-shadow:0px 0px 4px #ffea00;}
  37% {color: #120c00;text-shadow:0px 0px 4px #120c00;}
  41% {color: #120c00;text-shadow:0px 0px 4px #120c00;}
  42% {color: #ffb700;text-shadow:0px 0px 4px #ffb700;}
  85% {color: #ffb700;text-shadow:0px 0px 4px #ffb700;}
  86% {color: #120c00;text-shadow:0px 0px 4px #120c00;}
  95% {color: #120c00;text-shadow:0px 0px 4px #120c00;}
  96% {color: #ffea00;text-shadow:0px 0px 4px #ffea00;}
  100%{color: #ffea00;text-shadow:0px 0px 4px #ffea00;}
}

@keyframes fadeInText{
  1%  {color: #120c00;text-shadow:0px 0px 4px #120c00;}
  70% {color: #ffb700;text-shadow:0px 0px 14px #ffb700;}
  100%{color: #ffb700;text-shadow:0px 0px 4px #ffb700;}
}

@keyframes hueRotate{
  0%  {
    filter: hue-rotate(0deg);
  }
  50%  {
    filter: hue-rotate(-120deg);
  }
  100%  {
    filter: hue-rotate(0deg);
  } 
}*/
</style>