<template>
  <div class="body">
    <form @submit.prevent="submitForm" class="container">
      <div>
        <label class="logo"></label>
      </div>
      <div class="line">
        <hr />
      </div>
      <div>
        <!-- <label for="username"></label> -->
        <v-text-field class="Textbox-Text" id="username" v-model="username" type="text" required label="Email"
          variant="solo" />
      </div>
      <div>
        <!-- <label for="password">Password</label> -->
        <v-text-field class="Textbox-Text" id="password" v-model="password" type="password" required label="Password"
          variant="solo" />
      </div>
      <v-btn type="submit" size="large">Proceed</v-btn>
    </form>

  </div>
</template>

<script>
import axios from "axios";
import router from '@/VueRouter'
import vuexstore from '@/vuexstore';

export default {
  data() {
    return {
      username: "",
      password: "",
      errors: [],
    };
  },

  methods: {
    submitForm() {
      axios.post(`Login/Authenticate?`, {
        email: this.username,
        password: this.password,
      }).catch((error) => {
        if (error.response.status === 400) {
          this.errors.push("Invalid Username/Password");
          console.log("hit error");
        }
      }).then((response) => {
        if (!(response.data === null)) {
          localStorage.setItem("token", response.data.token);
          vuexstore.commit("roleid", response.data.roleID);
          vuexstore.commit("userid", response.data.clientID);
          vuexstore.commit("companyID", response.data.companyID);
          vuexstore.commit("guid", response.data.guid);

          if(response.data.companyID === (3 || 4)){
            router.push("/main");
          } else {
            router.push("/client-main");
          }
        }
        else {
          console.log("login failed");
        }
      });
    },
  },
  mounted() {
    localStorage.setItem("token", null);
    vuexstore.commit("roleid", null);
    vuexstore.commit("userid", null);
    vuexstore.commit("companyID", null);
    vuexstore.commit("guid", null);
  },
};
</script>

<style scoped>
.container {
  border-radius: 20px;
  width: 30vw;
  padding: 40px;
  background: #ffffff;
  position: relative;
  display: flexbox;
  align-items: center;
  text-align: center;
  box-shadow: 1vh 1vh rgba(0, 0, 0, 0.226);
}

.body {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('../../assets/EasyVerify_bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.Textbox-Text {
  color: black !important;
}

button[type="submit"] {
  background-color: #daa520;
  color: #fff;
  cursor: pointer;
  background-color: #daa520 !important;
  border-radius: 50px;
  border: 2px solid black;
  border-color: rgb(255, 255, 255);
}

.line hr {
  border: 0;
  height: 1px;
  background-color: #333;
  margin-top: 0;
  margin-bottom: 15%;
}
</style>
