<template>
    <div class="main-table">
        <div class="table-wrapper">
            <div class="table-headings">
                <h4 class="heading-text heading-clickable" @click="changeSortType('IDNumber')">ID</h4>
                <h4 class="heading-text heading-clickable" @click="changeSortType('PassportNum')">Passport</h4>
                <h4 class="heading-text">First Name</h4>
                <h4 class="heading-text">Surname</h4>
                <h4 class="heading-text heading-clickable" @click="changeSortType('CountryOfResidence')">Country</h4>
                <h4 class="heading-text heading-clickable" @click="changeSortType('SourceOfFunds')">Source Of Funds</h4>
                <h4 class="heading-text heading-clickable" @click="changeSortType('Nationality')">Nationality</h4>
                <h4 class="heading-text heading-clickable" @click="changeSortType('Status')">Status</h4>
            </div>

            <div v-for="item in customer" :key="item.idNumber" class="table-data">
                <p class="data-text">{{ item.idNumber }}</p>
                <p class="data-text">{{ item.passportNum }}</p>
                <p class="data-text">{{ item.firstName }}</p>
                <p class="data-text">{{ item.surname }}</p>
                <p class="data-text">{{ item.countryOfResidence }}</p>
                <p class="data-text">{{ item.sourceOfFunds }}</p>
                <p class="data-text">{{ item.nationality }}</p>
                <p class="data-text">{{ item.status }}</p>
            </div>

            <div v-if="customer.length === 0" class="table-data-empty">
                <p>No Customers Registered Within The Given Timeframe</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:
    {
        customer: {
            type: [],
            default: [
                {
                    idNumber: "",
                    passportNum: "",
                    firstName: "Test",
                    surname: "",
                    home_Address: "",
                    suburb: "",
                    city: "",
                    province: "",
                    postal_Code: "",
                    countryOfResidence: "",
                    sourceOfFunds: "",
                    nationality: "",
                    status: "",
                    email: "",
                    marketing: ""
                },
            ],
            require: true
        },
    },
    data(){
        return{
            custLength: this.customer,
        }
    },
    methods: {
        changeSortType(e){
            this.$emit('sortColChanged', e);
        }
    }
}
</script>

<style>
.main-table {
    display: flex;
    justify-content: center;
    
    width: 100%;

    margin-top: 5vh;
}

.table-wrapper{
    display: flex;
    flex-direction: column;

    align-items: center;
    
    width: 80%;
}

.table-headings {
    display: flex;
    flex-direction: row;
}

.table-data{
    display: flex;
    flex-direction: row;
}

.heading-text{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7vh;
    width: 9vw;
    text-align: left;
    font-size: small;
    background: #daa520bb;
    border-right: 0.5px solid white;
}

.heading-clickable{
    cursor: pointer;
}

.data-text{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 4.5vh;
    width: 9vw;

    text-align: left;

    font-size: small;

    border-bottom: 0.5px groove lightgrey;
}

.table-data-empty{
    margin-top: 1vh;
}
</style>