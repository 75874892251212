<template>
    <div style="width:85%; display: block;">
        <v-table class="table">
            <thead>
                <tr>
                    <th>UniqueID</th>
                    <th>ID / passport Number</th>
                    <th>WhoYou API Call: </th>
                    <th>Date and Time of WhoYou Call</th>
                    <th>Verification Status</th>
                    <th>CPB API Call</th>
                    <th>Date and time of CPB API Call</th>
                    <th>CPB Status</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="item in data" :key="item.UniqueID">
                    <td class="text">{{ item.UniqueID }}</td>
                    <td class="text">{{ item.ID }}</td>
                    <td class="text">{{ item.WhoYouCall }}</td>
                    <td class="text">{{ item.DOWhoYouCall }}</td>
                    <td class="text">{{ item.VerificationStatus }}</td>
                    <td class="text">{{ item.CPBcall }}</td>
                    <td class="text">{{ item.DOCPBcall }}</td>
                    <td class="text">{{ item.CPBStatus }}</td>
                </tr>
            </tbody>
        </v-table>
    </div>
</template>

<script>
export default {

    props:
    {
        data: {
            type: [],
            default: [
                {
                    UniqueID: "",
                    ID: "",
                    WhoYouCall: "Test",
                    DOWhoYouCall: "",
                    VerificationStatus: "",
                    CPBcall: "",
                    DOCPBcall: "",
                    CPBStatus: "",
                },
            ],
            require: true
        },
    }
}

</script>

<style>
.text {
    color: black;
    background-color: white;
    border: 2px solid black;
    border-color: #daa520;
    text-align: left;
    font-size: small;
}

.text:hover {
    background-color: #daa520;
    color: rgb(0, 0, 0);
    border: 2px solid black;
    border-color: rgb(0, 0, 0);
}

.table {
    color: black;
    background-color: white;
    border-color: gold;
    text-align: center;
    /* position: fixed; */
    bottom: 0;
    max-height: 60vh;
    margin: 1rem;
    font-size: small;
}

.headertext {
    color: black;
    background-color: white;
    border: 2px solid black;
    border-color: #daa520;
    text-align: center;
    font-size: small;
    font-weight: bold;
}
</style>