<template>
  <div class="body">
    <form @submit.prevent="submitForm" class="container">
      <div>
        <label class="logo"></label>
      </div>
      <div class="line">
        <hr />
      </div>
      <div>
        <v-text-field class="Textbox-Text" id="OTP" v-model="OTP" type="number" required label="Enter OTP" />
      </div>
      <v-btn class="buttonSubmit" type="submit" rounded="pill" size="large">Login</v-btn>
      <div>
        <v-btn class="button-resend" variant="outlined" rounded="pill" size="small" type="resend" color="#daa520">Resend
          OTP</v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import vuexstore from '@/vuexstore'
import router from '@/VueRouter'


export default {
  data() {
    return {
      OTP: "",
    };
  },
  methods: {
    submitForm() {
      vuexstore.commit("roleid",1);

      if (this.OTP.toString() === "123") {
        if (vuexstore.getters.roleid == 1) {
          router.push("/main");
        } else {
          router.push("/client-main");
        }
        
      } else {
        localStorage.removeItem("token");
        this.$store.actions.clear();

        alert("Invalid Details");
      }
    },
  },

};
</script>

<style>
label {
  margin-bottom: 4px;
  display: inline-block;
  width: 150px;
  text-align: left;
  padding: 1%;
  font-weight: bold;
}

.container {
  border-radius: 20px;
  width: 25%;
  padding: 40px;
  background: #ffffff;
  position: relative;
  display: flexbox;
  align-items: center;
  text-align: center;
}

.body {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Textbox-Text {
  color: black !important;
}

.buttonSubmit {
  font-weight: bolder;
  padding: 1%;
  background-color: #daa520;
  color: #fff;
  cursor: pointer;
  border-radius: 50px;
  border: 2px solid black;
  border-color: rgb(255, 255, 255);
  margin: 1%;
}

.button-resend {
  padding: 1%;
  background-color: white;
  color: #daa520;
  cursor: pointer;
  border: 2px solid black;
  border-color: #daa520;
  margin: 1%;
}

.line hr {
  border: 0;
  height: 1px;
  background-color: #333;
  margin-top: 0;
  margin-bottom: 15%;
}
</style>
