import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from './components/Login/Login_Component.vue'
import OTPPage from './components/Login/OTP_Component.vue'
import MainPage from "./components/MainComponents/MainPage.vue";
import ClientMainPage from "./components/MainComponents/ClientMainMenu.vue";
import unAuthorizedPage from './components/Login/UnAuthorized_Component.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: LoginPage },
        { path: '/otp', component: OTPPage },
        { path: '/main', component: MainPage, meta: { requiresAuth: true } },
        { path: '/client-main', component: ClientMainPage, meta: { requiresAuth2: true } },
        { path: '/unauthorized', component: unAuthorizedPage },

    ],
})

router.beforeEach((to, from, next) => {
    if(to.matched.some((route) => route.meta.requiresAuth || route.meta.requiresAuth2))
    {
        if((localStorage.getItem("token") === 'null' || localStorage.getItem("token") === "")){
            router.push("/");
        }

        if ((localStorage.getItem("token") !== 'null' || localStorage.getItem("token") !== "") && (from.path === ("/")))
        {
            next();
        }
    } else {
        // if(to.path === "/" && from.path !== "/"){
        //     window.location.reload();
        // }

        next();
    }
})

export default router;