<template>
    <div class="main-table">
        <div class="table-wrapper">
            <div class="table-headings">
                <h4 class="heading-text">ID</h4>
                <h4 class="heading-text">Passport</h4>
                <h4 class="heading-text">First Name</h4>
                <h4 class="heading-text">Surname</h4>
                <h4 class="heading-text">Country</h4>
                <h4 class="heading-text">Source Of Funds</h4>
                <h4 class="heading-text">Nationality</h4>
                <h4 class="heading-text">Status</h4>
            </div>

            <div v-for="item in data" :key="item.idNumber" class="table-data">
                <p class="data-text">{{ item.idNumber }}</p>
                <p class="data-text">{{ item.passportNum }}</p>
                <p class="data-text">{{ item.firstName }}</p>
                <p class="data-text">{{ item.surname }}</p>
                <p class="data-text">{{ item.countryOfResidence }}</p>
                <p class="data-text">{{ item.sourceOfFunds }}</p>
                <p class="data-text">{{ item.nationality }}</p>
                <p class="data-text">{{ item.status }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:
    {
        data: {
            type: [],
            default: [
                {
                    idNumber: "",
                    passportNum: "",
                    firstName: "Test",
                    surname: "",
                    home_Address: "",
                    suburb: "",
                    city: "",
                    province: "",
                    postal_Code: "",
                    countryOfResidence: "",
                    sourceOfFunds: "",
                    nationality: "",
                    status: "",
                    email: "",
                    marketing: ""
                },
            ],
            require: true
        },
    }
}
</script>

<style>
.main-table {
    display: flex;
    justify-content: center;
    
    width: 100%;

    margin-top: 5vh;
}

.table-wrapper{
    display: flex;
    flex-direction: column;

    align-items: center;
    
    width: 80%;
}

.table-headings {
    display: flex;
    flex-direction: row;
}

.table-data{
    display: flex;
    flex-direction: row;
}

.heading-text{
    display: flex;
    align-items: center;

    height: 7vh;
    width: 9vw;

    text-align: left;

    font-size: medium;
}

.data-text{
    display: flex;
    align-items: center;

    height: 4.5vh;
    width: 9vw;

    text-align: left;

    font-size: small;

    border-bottom: 0.5px groove lightgrey;
}
</style>