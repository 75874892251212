<template>
    <div class="center">
        <div class="card">
            <Doughnut :data="Chartdata" :options="options" />
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend,Title } from 'chart.js'
import { Doughnut } from 'vue-chartjs'
// import pieInfo from '@/Models/pieChartInfo'


ChartJS.register(ArcElement, Tooltip, Legend,Title)

export default {
    name: 'App',
    components: {
        Doughnut
    },

    props:
    {
        data: {
            type: [],
            default: [
                { name: "1", amount: 10 },
                { name: "2", amount: 10 },
            ],
            require: true
        },
        colors: {
            type: [],
            default: ["#FAEBD7", "#CB410B", "#4C5866", "#301934", "#800020", "#F5A400"],
        },
        title: {
            type: String,
            default: "analytics"
        }
    },

    data() {
        return {
            Chartdata: {
                labels: this.data.map(e => e.name),

                datasets: [
                    {
                        fontColor: "white",
                        backgroundColor: this.colors,
                        data: this.data.map(e => parseInt(e.amount)),
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                title: "Testing Charts",
                borderColor: "black",
                backgroundColor: "#00000000",
                hoverBackgroundColor: "#000000",
                hoverBorderColor: "White",
                fontColor: "black",
                color: "black",
                // legend: {
                //     fontColor: 'black',
                //     display: false
                // },
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: this.title,
                        fontColor: "black"
                    },
                },
            },
            // backgroundColors: []
        }
    },
    mounted() {
    },


}
</script>

<style>
/* .card {
    background: linear-gradient(315deg, rgba(255, 233, 205, 0.44), antiquewhite);

    box-shadow: -10px -10px 20px #333333a2,
        10px 10px 20px #67676788;
    width: 200px;
    height: 200px;
} */

.card {
    width: 10vw;
    height: 10vw;
    margin-left: 1rem;
    background: white;
    border: 3px groove white;
    transition: all .5s ease-in-out;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-shadow: 1vh 1vh rgba(0, 0, 0, 0.226);
}

.card:hover {
    transform: translateY(-5px);
    transition: all .5s ease-in-out;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>