// main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './VueRouter';
import './axios'
import "vue3-dropdown-navbar/preflight.css";
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { Bar } from 'vue-chartjs'
import vuex from './vuexstore';


const vuetify = createVuetify({
  components,
  directives,
})




const app = createApp(App)
app.use(router)
app.use(vuetify)
app.use(vuex)
app.use(Bar)
app.mount('#app')
