<template>
    <ul class="paginator-container">
        <li class="pagText">
            <button class="pagText" type="button" @click="onClickFirstPage" :disabled="isInFirstPage">
                &lt;&lt;
            </button>
        </li>

        <li class="pagText">
            <button class="pagText" type="button" @click="onClickPreviousPage" :disabled="isInFirstPage">
                &lt;
            </button>
        </li>

        <!-- Visible Buttons Start -->

        <li v-for="page in pages" :key="page.name" class="pagText">
            <button type="button" class="pagText" @click="onClickPage(page.name)" :disabled="page.isDisabled">
                {{ page.name }}
            </button>
        </li>

        <!-- Visible Buttons End -->

        <li class="pagText">
            <button class="pagText" type="button" @click="onClickNextPage" :disabled="isInLastPage">
                &gt;
            </button>
        </li>

        <li class="pagText">
            <button class="pagText" type="button" @click="onClickLastPage" :disabled="isInLastPage">
                &gt;&gt;
            </button>
        </li>
    </ul>
</template>
  
<script>
export default {
    props: {
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 3
        },
        totalPages: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        }

    },
    computed: {
        startPage() {
            // When on the first page
            if (this.currentPage === 1) {
                return 1;
            }

            // When on the last page
            if (this.currentPage === this.totalPages) {
                const start = this.totalPages - (this.maxVisibleButtons - 1);

                if (start === 0) {
                    return 1;
                } else {
                    return start;
                }
            }

            // When inbetween
            return this.currentPage - 1;
        },
        isInFirstPage() {
            return this.currentPage === 1;
        },
        isInLastPage() {
            return this.currentPage === this.totalPages;
        },
        pages() {
            const range = [];

            for (
                let i = this.startPage;
                i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
                i++
            ) {
                range.push({
                    name: i,
                    isDisabled: i === this.currentPage
                });
            }

            return range;
        }
    }
    ,
    methods: {

        onClickFirstPage() {
            this.$emit('pagechanged', 1);
        },
        onClickPreviousPage() {
            this.$emit('pagechanged', this.currentPage - 1);
        },
        onClickPage(page) {
            this.$emit('pagechanged', page);
        },
        onClickNextPage() {
            this.$emit('pagechanged', this.currentPage + 1);
        },
        onClickLastPage() {
            this.$emit('pagechanged', this.totalPages);
        }

    }
}


</script>


<style>
.paginator-container{
    display: flex;
    margin-right: 4vw;
    word-spacing: 1rem;
    flex-direction: row;
    justify-content: flex-end;
}

.pagText {
    font-size: 1vw;
    margin: 0.25vw;
    padding: 1vh 1vh;
    border-radius: 1rem;
}

.pagText :hover {
    background-color: #daa520bb;
}
</style>