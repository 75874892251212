<template>
    <div class="client-navbar">
        <ClientDashBoard :menu-items="menuItems" :menu-title="EasyVerify" v-on:set-child-data="updateIsOpen"
            v-on:set-client-view="updateView" />
    </div>
    <div :class="isOpened ? 'dashboard-body-open' : 'dashboard-body-closed'">
        <div class="client-dashboard" v-if="clientView === true">
            <ClientStats :Today="todaystats" :Weekly="weeklystats"></ClientStats>
            <!-- <DataRangeCombobox @update:model-value="changeRange" class="client-daterange"></DataRangeCombobox> -->

            <div class="client-db-header">
                <div class="client-db-header-cb">
                    <DataRangeCombobox @update:model-value="changeRange" class="client-daterange"></DataRangeCombobox>
                </div>
            </div>

            <div class="client-piechart">
                <ClientPieChart :data="MarketingInfo" :title="titleMarketing"></ClientPieChart>
                <ClientPieChart :data="SOFInfo" :title="titleSOF"></ClientPieChart>
                <ClientPieChart :data="NationalityInfo" :title="NationalityTitle"></ClientPieChart>
                <ClientPieChart :data="StatusInfo" :title="StatusTitle"></ClientPieChart>
            </div>
        </div>

        <div class="client-database" v-if="clientView === false">
            <div class="client-db-header">
                <div class="client-db-header-cb">
                    <DataRangeCombobox @update:model-value="changeRange" class="client-daterange"></DataRangeCombobox>
                </div>
            </div>

            <ClientDataTable :customer="customers" @sortColChanged="sortColChanged"></ClientDataTable>

            <div class="client-db-footer">
                <button class="extract-csv" v-on:click="extractCSV">Extract CSV</button>

                <ClientPaginator :totalPages="pageCount" :perPage="10" :currentPage="currentPage"
                    @pagechanged="onPageChange" />
            </div>

            <div :class="isOpened ? 'client-csv-popup-container-opened' : 'client-csv-popup-container'" v-if="showFilePath ===  true">
                <div class = "client-csv-popup-inner">
                    <h2>CSV File Created</h2>
                    <!-- <h4>Path: <span class="csv-path-url">C:\Users\Preshen\Source\Repos\EasyVerifyInternalAPI\EasyVerify.API\133365741741621596.csv</span></h4> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import vuexstore from '@/vuexstore';
import ClientDashBoard from './ClientDashboard/ClientDashBoard.vue';
import DataRangeCombobox from './ClientDashboard/ClientDateRange.vue';
import ClientPieChart from './ClientDashboard/ClientPieChart.vue';
import ClientDataTable from './ClientDashboard/ClientDataTable.vue';
import ClientStats from './ClientDashboard/ClientStats.vue'
import ClientPaginator from './ClientDashboard/ClientPaginator.vue'
// import ApiDataTable_Component from './ClientDashboard/ApiDataTable_Component.vue';

export default {
    data() {
        return {
            menuItems: [{
                link: "#",
                name: "Dashboard",
                tooltip: "Dashbaord",
                icon: "bx-pie-chart-alt-2",
                filterTerm: "",
            },
            {
                link: "#",
                name: "Client Database",
                tooltip: "Client Database",
                icon: "bx-pie-chart-alt-2",
                filterTerm: "",
            },
            ],
            NationalityInfo: vuexstore.state.Nationality,
            StatusInfo: vuexstore.state.StatusInfo,
            SOFInfo: vuexstore.state.SoFInfo,
            MarketingInfo: vuexstore.state.Marketing,
            filterTerm: vuexstore.getters.guid,
            titleMarketing: 'Signed up for marketing',
            titleSOF: 'Source of funds typing ',
            WhoYouTitle: 'WhoYou API Call',
            CPBTitle: 'CPB API Call',
            NationalityTitle: 'Nationality',
            StatusTitle: 'Verification Status',
            currentPage: 1,
            noOfPages: 0,
            todaystats: 0,
            weeklystats: 0,
            DataorApi: vuexstore.getters.viewType,
            isOpened: true,
            clientView: true,
            pageCount: vuexstore.state.PageCount,
            customers: vuexstore.getters.CustomerInfo,
            sortCol: "IDNumber",
            sortType: "DESC",
            showFilePath: false,
            filePath: "",
        }
    },
    components:
    {
        ClientDashBoard,
        DataRangeCombobox,
        ClientPieChart,
        ClientDataTable,
        ClientStats,
        ClientPaginator,
    },
    methods:
    {
        changeRange() {
            console.log(this.currentPage);
            this.refreshPage()
        },
        updateIsOpen(checkOpen) {
            this.isOpened = checkOpen;
        },
        updateView(checkView) {
            this.clientView = checkView;
        },
        onPageChange(page) {
            this.currentPage = page;
            this.refreshPage()
        },
        sortColChanged(col){
            if(this.sortCol === col){
                if(this.sortType === 'ASC'){
                    this.sortType = 'DESC';
                } else {
                    this.sortType = 'ASC';
                }
            } else {
                this.sortCol = col;
            }

            this.refreshPage();
        },

        extractCSV() {
            // window.open(`https://localhost:44368/api/Customer/CreateCSV?guid=${vuexstore.getters.guid}&PageNum=${this.currentPage}&SortingCol=${this.sortCol}&SortType=${this.sortType}&DateRange=${vuexstore.getters.DateRange}`, "_blank");
            window.open(`https://evbackofficeapi.easygamesdemo.co.za/api/Customer/CreateCSV?guid=${vuexstore.getters.guid}&PageNum=${this.currentPage}&SortingCol=${this.sortCol}&SortType=${this.sortType}&DateRange=${vuexstore.getters.DateRange}`, "_blank");
        },

        refreshPage() {
            axios
                .get(`Customer/GetPagination?Guid=${vuexstore.getters.guid}&DateRange=${vuexstore.getters.DateRange}`)
                .then(response => {
                    console.log("response.data.paginationCount", response.data.paginationCount);
                    if(this.pageCount !== response.data.paginationCount){
                        console.log("Here");

                        
                        vuexstore.commit('PageCount', response.data.paginationCount);
                        this.pageCount = vuexstore.state.PageCount;
                    }
                });

                console.log(vuexstore.state.paginationCount);
                console.log('pagecount - refreshed', this.pageCount);

            axios
                .post(`Customer/GetCustomerDetailsPagination`,
                    {
                        guid: vuexstore.getters.guid,
                        pageNum: this.currentPage,
                        sortingCol: this.sortCol,
                        sortType: this.sortType,
                        dateRange: vuexstore.getters.DateRange
                    }
                )
                .then(response => {
                    vuexstore.commit('CustomerInfo', response.data);
                    this.customers = vuexstore.state.CustomerInfo;
                });
            axios
                .get(`Customer/GetNationality?Guid=${vuexstore.getters.guid}&DateRange=${vuexstore.getters.DateRange}`)
                .then(response => {
                    if (response.data.length === 0) {
                        const infomation = [];

                        vuexstore.commit('Nationality', infomation);
                        this.NationalityInfo = vuexstore.state.Nationality;
                    } else {
                        const infomation = response.data.map(data => {
                            return {
                                name: data.nationality,
                                amount: parseInt(data.tally),
                            };
                        });

                        vuexstore.commit('Nationality', infomation);
                        this.NationalityInfo = vuexstore.state.Nationality;
                    }
                });
            axios
                .get(`Customer/GetStatus?Guid=${vuexstore.getters.guid}&DateRange=${vuexstore.getters.DateRange}`)
                .then(response => {
                    if (response.data.length === 0) {
                        const infomation = [];

                        vuexstore.commit('StatusInfo', infomation);
                        this.StatusInfo = vuexstore.state.StatusInfo;
                    } else {
                        const infomation = response.data.map(data => {
                            return {
                                name: data.status,
                                amount: parseInt(data.tally),
                            };
                        });

                        vuexstore.commit('StatusInfo', infomation);
                        this.StatusInfo = vuexstore.state.StatusInfo;
                    }
                });
            axios
                .get(`Customer/GetMarketing?Guid=${vuexstore.getters.guid}&DateRange=${vuexstore.getters.DateRange}`)
                .then(response => {
                    if (response.data.length === 0) {
                        const infomation = [];

                        vuexstore.commit('Marketing', infomation);
                        this.MarketingInfo = vuexstore.state.Marketing;
                    } else {
                        const infomation = response.data.map(data => {
                            return {
                                name: data.marketing,
                                amount: parseInt(data.tally),
                            };
                        });

                        vuexstore.commit('Marketing', infomation);
                        this.MarketingInfo = vuexstore.state.Marketing;
                    }
                });
            axios
                .get(`Customer/GetSourceOfFunds?Guid=${vuexstore.getters.guid}&DateRange=${vuexstore.getters.DateRange}`)
                .then(response => {
                    if (response.data.length === 0) {
                        const infomation = [];

                        vuexstore.commit('SoFInfo', infomation);
                        this.SOFInfo = vuexstore.state.SoFInfo;
                    } else {
                        const infomation = response.data.map(data => {
                            return {
                                name: data.sourceOfFunds,
                                amount: parseInt(data.tally),
                            };
                        });

                        vuexstore.commit('SoFInfo', infomation);
                        this.SOFInfo = vuexstore.state.SoFInfo;
                    }
                });

            axios
                .get(`Customer/GetOnboardedCustomers?Guid=${vuexstore.getters.guid}`)
                .then(response => {
                    const infomation = response.data.map(data => {
                        return {
                            daily: parseInt(data.daily),
                            Weekly: parseInt(data.weekly),
                        };
                    });

                    vuexstore.commit('Onboarding', infomation);
                    this.todaystats = vuexstore.state.Onboarding[0].daily;
                    this.weeklystats = vuexstore.state.Onboarding[0].weekly;
                });
        },
    },
    beforeMount() {
        if (localStorage.getItem('token') === ''
            || localStorage.getItem('token') === 'null' || localStorage.getItem('token') === null) {
                this.$router.push("/");
        }
            
        axios
            .get(`Customer/GetPagination?Guid=${vuexstore.getters.guid}&DateRange=${vuexstore.getters.DateRange}`)
            .then(response => {
                vuexstore.commit('PageCount', response.data.paginationCount);
                this.pageCount = vuexstore.state.PageCount;
            });

            
        axios
            .post(`Customer/GetCustomerDetailsPagination`,
                {
                    guid: vuexstore.getters.guid,
                    pageNum: this.currentPage,
                    sortingCol: this.sortCol,
                    sortType: this.sortType,
                    dateRange: vuexstore.getters.DateRange
                }
            )
            .then(response => {
                vuexstore.commit('CustomerInfo', response.data);
                this.customers = vuexstore.state.CustomerInfo;
            });

        axios
            .get(`Customer/GetNationality?Guid=${vuexstore.getters.guid}&DateRange=${vuexstore.getters.DateRange}`)
            .then(response => {
                if (response.data.length === 0) {
                    const infomation = [];

                    vuexstore.commit('Nationality', infomation);
                    this.NationalityInfo = vuexstore.state.Nationality;
                } else {
                    const infomation = response.data.map(data => {
                        return {
                            name: data.nationality,
                            amount: parseInt(data.tally),
                        };
                    });

                    vuexstore.commit('Nationality', infomation);
                    this.NationalityInfo = vuexstore.state.Nationality;
                }
            });
        axios
            .get(`Customer/GetStatus?Guid=${vuexstore.getters.guid}&DateRange=${vuexstore.getters.DateRange}`)
            .then(response => {
                if (response.data.length === 0) {
                    const infomation = [];

                    vuexstore.commit('StatusInfo', infomation);
                    this.StatusInfo = vuexstore.state.StatusInfo;
                } else {
                    const infomation = response.data.map(data => {
                        return {
                            name: data.status,
                            amount: parseInt(data.tally),
                        };
                    });

                    vuexstore.commit('StatusInfo', infomation);
                    this.StatusInfo = vuexstore.state.StatusInfo;
                }
            });
        axios
            .get(`Customer/GetMarketing?Guid=${vuexstore.getters.guid}&DateRange=${vuexstore.getters.DateRange}`)
            .then(response => {
                if (response.data.length === 0) {
                    const infomation = [];

                    vuexstore.commit('Marketing', infomation);
                    this.MarketingInfo = vuexstore.state.Marketing;
                } else {
                    const infomation = response.data.map(data => {
                        return {
                            name: data.marketing,
                            amount: parseInt(data.tally),
                        };
                    });

                    vuexstore.commit('Marketing', infomation);
                    this.MarketingInfo = vuexstore.state.Marketing;
                }
            });
        axios
            .get(`Customer/GetSourceOfFunds?Guid=${vuexstore.getters.guid}&DateRange=${vuexstore.getters.DateRange}`)
            .then(response => {
                if (response.data.length === 0) {
                    const infomation = [];

                    vuexstore.commit('SoFInfo', infomation);
                    this.SOFInfo = vuexstore.state.SoFInfo;
                } else {
                    const infomation = response.data.map(data => {
                        return {
                            name: data.sourceOfFunds,
                            amount: parseInt(data.tally),
                        };
                    });

                    vuexstore.commit('SoFInfo', infomation);
                    this.SOFInfo = vuexstore.state.SoFInfo;
                }
            });

        axios
            .get(`Customer/GetOnboardedCustomers?Guid=${vuexstore.getters.guid}`)
            .then(response => {
                const infomation = response.data.map(data => {
                    return {
                        daily: parseInt(data.daily),
                        Weekly: parseInt(data.weekly),
                    };
                });

                vuexstore.commit('Onboarding', infomation);
                this.todaystats = vuexstore.state.Onboarding[0].daily;
                this.weeklystats = vuexstore.state.Onboarding[0].Weekly;
            });
    },
}
</script>

<style scoped>
.client-body {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: flex-end;
}


/* Client Navbar */
.client-navbar {
    width: 1vw;
}

.dashboard-body-open {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 79%;
    height: 90%;
}

.dashboard-body-closed {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 94%;
    height: 90%;
}

/* Client Dashboard */
.client-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
    flex-direction: column;
}

.client-daterange {
    margin-top: 10vh;
    width: 100%;
    cursor: pointer;
}

.client-piechart {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 60%;
    margin-top: 5vh;
}

/* Client Database */

.client-db-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-db-header-cb {
    width: 20%;
}

.client-database {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.client-db-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    margin-top: 2vh;
}

.extract-csv {
    background-color: #daa520bb;
    color: white;
    border-radius: 1vw;
    font-size: 1vw;
    width: 15%;
    height: 5vh;
}

.client-csv-popup-container{
    display: flex;
    position: absolute;
    width: 94%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 11vh;
    background-color: rgba(128, 128, 128, 0.253);
    border-radius: 1vw;
}

.client-csv-popup-container-opened{
    display: flex;
    position: absolute;
    width: 79%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 11vh;
    background-color: rgba(128, 128, 128, 0.253);
    border-radius: 1vw;
}

.client-csv-popup-inner{
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 35%;
    justify-content: center;
    align-items: center;
    background-color: #ffffffc4;
    border-radius: 1vw;
}

.client-csv-popup-inner h2{
    font-size: 5vh;
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
}

.client-csv-popup-inner h4{
    font-size: 4vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
}

.csv-path-url{
    font-size: 2vh;
}

</style>