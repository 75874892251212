import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";


const state={
    user:null,
    roleid:null,
    companyID:null,
    guid:null,
    viewType:null,
    DateRange: 'All Time',
    Nationality: [],
    StatusInfo: [],
    Marketing: [],
    SoFInfo: [],
    Onboarding: [],
    ClientInfo: [],
    ClientView: true,
    PageCount: 1,
    CustomerInfo: [],
};

export default createStore({
    state,
    mutations: {
        roleid(state,roleid){
            state.roleid=roleid;
        },
        userid(state,userid){
            state.userid=userid;
        },
        companyID(state,companyID){
            state.companyID=companyID;
        },
        guid(state,guid){
            state.guid=guid;
        },
        viewType(state,viewType){
            state.viewType=viewType;
        },
        DateRange(state,DateRange){
            state.DateRange=DateRange;
        },
        Nationality(state,Nationality){
            state.Nationality=Nationality;
        },
        StatusInfo(state,StatusInfo){
            state.StatusInfo=StatusInfo;
        },
        Marketing(state,Marketing){
            state.Marketing=Marketing;
        },
        SoFInfo(state,SoFInfo){
            state.SoFInfo=SoFInfo;
        },
        Onboarding(state,Onboarding){
            state.Onboarding=Onboarding;
        },
        PageCount(state,PageCount){
            state.PageCount=PageCount;
        },
        ClientInfo(state,ClientInfo){
            state.ClientInfo=ClientInfo;
        },
        ClientView(state,ClientView){
            state.ClientView=ClientView;
        },
        CustomerInfo(state,CustomerInfo){
            state.CustomerInfo=CustomerInfo;
        },
    },
    setters: {
        roleid(roleid) {
            this.state.roleid = roleid;
        },
        userid(userid) {
            this.state.userid = userid;
        },
        companyID(companyID) {
            this.state.companyID = companyID;
        },
        guid(guid) {
            this.state.guid = guid;
        },
        viewType(viewType) {
            this.state.viewType = viewType;
        },
        DateRange(DateRange) {
            this.state.DateRange = DateRange;
        },
        Nationality(Nationality) {
            this.state.Nationality = Nationality;
        },
        StatusInfo(StatusInfo) {
            this.state.StatusInfo = StatusInfo;
        },
        Marketing(Marketing) {
            this.state.Marketing = Marketing;
        },
        SoFInfo(SoFInfo) {
            this.state.SoFInfo = SoFInfo;
        },
        Onboarding(Onboarding) {
            this.state.Onboarding = Onboarding;
        },
        PageCount(PageCount) {
            this.state.PageCount = PageCount;
        },
        ClientInfo(ClientInfo) {
            this.state.ClientInfo = ClientInfo;
        },
        ClientView(ClientView) {
            this.state.ClientView = ClientView;
        },
        CustomerInfo(CustomerInfo) {
            this.state.CustomerInfo = CustomerInfo;
        },
    },
    actions: {
        clear() {
            this.state.roleid = '',
                this.state.userid = ''
        },
        roleid(context,roleid){
            context.commit('roleid',roleid);
        },
        userid(context,userid){
            context.commit('userid',userid);
        },
        companyID(context,companyID){
            context.commit('companyID',companyID);
        },
        guid(context,guid){
            context.commit('guid',guid);
        },
        viewType(context,viewType){
            context.commit('viewType',viewType);
        },
        DateRange(context,DateRange){
            context.commit('DateRange',DateRange);
        },
        Nationality(context,Nationality){
            context.commit('Nationality',Nationality);
        },
        StatusInfo(context,StatusInfo){
            context.commit('StatusInfo',StatusInfo);
        },
        Marketing(context,Marketing){
            context.commit('Marketing',Marketing);
        },
        SoFInfo(context,SoFInfo){
            context.commit('SoFInfo',SoFInfo);
        },
        Onboarding(context,Onboarding){
            context.commit('Onboarding',Onboarding);
        },
        PageCount(context,PageCount){
            context.commit('PageCount',PageCount);
        },
        ClientInfo(context,ClientInfo){
            context.commit('ClientInfo',ClientInfo);
        },
        ClientView(context,ClientView){
            context.commit('ClientView',ClientView);
        },
        CustomerInfo(context,CustomerInfo){
            context.commit('CustomerInfo',CustomerInfo);
        },
    },
    getters: {
        roleid: function (state) {
            return `${state.roleid}`
        },
        userid: function (state) {
            return `${state.userid}`
        },
        companyID: function (state) {
            return `${state.companyID}`
        },
        guid: function (state) {
            return `${state.guid}`
        },
        viewType: function (state) {
            return `${state.viewType}`
        },
        DateRange: function (state) {
            return `${state.DateRange}`
        },
        Nationality: function (state) {
            return state.Nationality
        },
        StatusInfo: function (state) {
            return state.StatusInfo
        },
        Marketing: function (state) {
            return state.Marketing
        },
        SoFInfo: function (state) {
            return state.SoFInfo
        },
        Onboarding: function (state) {
            return state.Onboarding
        },
        PageCount: function (state) {
            return `${state.PageCount}`
        },
        ClientInfo: function (state) {
            return state.ClientInfo
        },
        ClientView: function (state) {
            return `${state.ClientView}`
        },
        CustomerInfo: function (state) {
            return state.CustomerInfo
        },
    },
    plugins:[createPersistedState()]
})