<template>
    <v-combobox :label="title" :items="['Client Database', 'API Call-Log']" variant="solo" v-model="selected"
        @update:model-value="handleChange"></v-combobox>
</template>

<script>
import vuexstore from '@/vuexstore';

export default {
    data() {
        return {
            selected: ""
        }
    },
    props: {
        title: {
            type: String,
            default: "EasyGames"
        }
    },
    mounted() {


    },
    methods: {
        handleChange() {

            if (this.selected === "Client Database") {

                vuexstore.commit("viewType", true);
            }
            else if (this.selected === "API Call-Log") {

                vuexstore.commit("viewType", false);
            }
        }
    }
}
</script>

<style>
.v-combobox {
    background-color: rgba(165, 42, 42, 0);
    color: aqua;
}

.v-combobox--single.v-text-field input {
    background-color: rgba(63, 10, 10, 0);
    color: rgb(0, 0, 0);
}

.v-combobox .v-field:not(.v-field--focused) input {
    color: rgb(0, 0, 0);
}

.v-input--density-default .v-field--variant-solo,
.v-input--density-default .v-field--variant-filled {

    background-color: rgba(222, 184, 135, 0.795);
}

.v-field--variant-solo {}
</style>
