<template>
  <div class="background">
    <router-view />
  </div>
</template>

<script>
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0%;
}

.background {
  background-color: white !important;
  height: 100vh;
  width: 100vw;
}

.logo {
  background-image: url('assets/easy verify-3.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
  text-align: center;
  align-self: center;
  width: 150px;
  height: 85px;
}
</style>
