<template>
    <div class="center">
        <div class="card">
            <div v-if="data.length === 0" class="doughnut-no-data">
                <p class="doughnut-title">{{ title }}</p>
                <p class="doughnut-content">No Data Within The Given Timeframe </p>
            </div>
            <Doughnut v-if="data.length > 0" :data="Chartdata" :options="options" />

        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Title, LinearScale  } from 'chart.js'
import { Doughnut } from 'vue-chartjs'


ChartJS.register(ArcElement, Tooltip, Title, LinearScale)

export default {
    name: 'App',
    components: {
        Doughnut,
    },

    props:
    {
        data: {
            type: [],
            default: [
                { name: "1", amount: 10 },
                { name: "2", amount: 10 },
            ],
            require: true
        },
        colors: {
            type: [],
            default: ["#FAEBD7", "#CB410B", "#4C5866", "#301934", "#800020", "#F5A400"],
        },
        title: {
            type: String,
            default: "analytics"
        }
    },

    data() {
        return {
            Chartdata: {
                labels: this.data.map(e => e.name),

                datasets: [
                    {
                        fontColor: "white",
                        backgroundColor: this.colors,
                        data: this.data.map(e => parseInt(e.amount)),
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                title: "Testing Charts",
                borderColor: "white",
                backgroundColor: "transparent",
                hoverBackgroundColor: "transparent",
                hoverBorderColor: this.colors,
                fontColor: "black",
                color: "black",
                plugins: {
                    legend:{
                        pagination: {
                            activeStyle: {
                                fill: 'blue',
                                strokeWidth: 0,
                            },
                            inactiveStyle: {
                                fillOpcacity: 0,
                            },
                            marker: {
                                size: 18,
                            }
                        },
                        display: true,
                        position: 'bottom',
                        align: 'center',
                        labels: {
                            color: "Black",
                            usePointStyle: true,
                            pointStyle: 'rect',
                        },
                    },
                    title: {
                        display: true,
                        text: this.title,
                        fontColor: "black"
                    },
                },
            },

        }
    },
    mounted() { },
    updated() {
        if (this.Chartdata.labels.length !== this.data.length) {
            this.Chartdata = {
                labels: this.data.map(e => e.name),

                datasets: [
                    {
                        fontColor: "white",
                        backgroundColor: this.colors,
                        data: this.data.map(e => parseInt(e.amount)),
                    }
                ]
            }
        }
    }


}
</script>

<style>
.card {
    width: 12vw;
    height: 12vw;
    background: white;
    border: 3px groove white;
    transition: all .5s ease-in-out;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-shadow: 1vh 1vh rgba(0, 0, 0, 0.226);
}

.card:hover {
    transform: translateY(-5px);
    transition: all .5s ease-in-out;
}

.center {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.doughnut-no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.doughnut-title {
    margin-top: 1vh;
    margin-bottom: 4vh;
    font-size: 1.6vh;
    font-family: sans-serif;
    font-weight: bold;
    color: grey;
}

.doughnut-content {
    width: 60%;
    font-size: 1.6vh;
    font-family: sans-serif;
    font-weight: bold;
    color: black;
    text-align: center;
}
</style>