<template>
    <div class="Statscard">
        <div class="Statscard-top">
            <div class="Statstitle">
                <p class="Statstitle-text">
                    Number of Clients on Boarded
                </p>
            </div>
        </div>

        <div class="Statsdata">
            <div class="statsdata-content">
                <p>
                    Today:
                </p>
                <p class="percent">
                    {{ getToday() }}
                </p>
            </div>
        </div>

        <div class="Statsdata">
            <div class="statsdata-content">
                <p>
                    Weekly:
                </p>
                <p class="percent">
                    {{ getWeekly() }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import vuexstore from '@/vuexstore';


export default {
    props:
    {
        Today: {
            type: Number,
            default: 0
        },
        Weekly: {
            type: Number,
            default: 0
        }
    },
    methods:{
        getToday(){
            if(vuexstore.getters.Onboarding.length !== 0){
                return vuexstore.getters.Onboarding[0].daily;
            } else {
                return 0
            }
        },

        getWeekly(){
            if(vuexstore.getters.Onboarding.length !== 0){
                return vuexstore.getters.Onboarding[0].Weekly;
            } else {
                return 0
            }
        }
    }
}
</script>

<style scoped>
.Statscard {
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    /* max-width: 320px; */
    width: 25vw;
    height: 25vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Statscard-top {
    background-color: #daa520bb;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    width: 25vw;
    height: 40%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    justify-content: center;
}

.Statstitle {
    display: flex;
    align-items: center;
}

.Statstitle span {
    position: relative;
    padding: 0.5rem;
    background-color: #10B981;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 9999px;
}

.Statstitle span svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    height: 1rem;
}

.Statstitle-text {
    margin-left: 0.5rem;
    color: #000000;
    font-size: 2vh;
}

.StatstitleBackround {
    background-color: #daa52089;
    width: 100%;
    height: 10%;
}

.statsText {
    margin-left: 0.5rem;
    color: #daa520;
    font-weight: 600;
}

.Statsdata {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 32%;
    justify-content: center;
}

.statsdata-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 320px;
    height: 20%;
}

.Statsdata p {
    color: #daa520;
    font-size: 2vh;
    text-align: left;
    border-radius: 1rem;
}

.percent {
    margin-left: 0.5rem;
    color: #000000 !important;
    background-color: rgb(237 237 237);
    font-weight: 500;
    display: flex;
    text-align: center;
    justify-content: center;
    height: 4.25vh;
    align-items: center;
}

.Statsdata .range {
    position: relative;
    background-color: #E5E7EB;
    width: 100%;
    height: 0.5rem;
    border-radius: 0.25rem;
}

.Statsdata .range .fill {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #10B981;
    width: 76%;
    height: 100%;
    border-radius: 0.25rem;
}</style>