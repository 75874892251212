<template >
    <Dashboard_Component :menu-items="ClientInfo" :menu-title="EasyVerify" @reload="refreshPage" v-on:set-child-data="updateIsOpen" />

    <div :class="isOpened ? 'dashboard-body-open' : 'dashboard-body-closed'">
        <DataRangeCombobox @update:model-value="changeRange"></DataRangeCombobox>

        <div v-if="DataorApi">
            <div style="display: grid; grid-template-columns: repeat(3,2fr); justify-items: center; align-items: center;">
                <div style="display: grid; grid-template-columns: repeat(2,1fr); width: 90%;">

                    <PieChart_Component :data="MarketingInfo" :title="titleMarketing"></PieChart_Component>
                    <PieChart_Component :data="SOFInfo" :title="titleSOF"></PieChart_Component>

                </div>
                <div style="display: grid; grid-template-columns: repeat(2,1fr); width: 90%;">
                    <PieChart_Component :data="NationalityInfo" :title="NationalityTitle"></PieChart_Component>
                    <PieChart_Component :data="StatusInfo" :title="StatusTitle"></PieChart_Component>

                </div>
                <div>
                    <Statistics_component :Today="todaystats" :Weekly="weeklystats"></Statistics_component>
                </div>
            </div>
        </div>
        <div v-else>
            <div style="display: grid; gap: 1rem; grid-template-columns: repeat(3,2fr); width: 85vw; height: 30vh;">
                <PieChart_Component :data="MarketingInfo" :title="CPBTitle"></PieChart_Component>
                <PieChart_Component :data="SOFInfo" :title="WhoYouTitle"></PieChart_Component>
                <div class="containerCenter">
                    <Statistics_component :Today="todaystats" :Weekly="weeklystats"></Statistics_component>

                </div>
            </div>
        </div>

        <div class="row" style=" width: 100%;">

            <div v-if="DataorApi">
                <DataTable_Component :data="customers" />
                <Pageinator_Component :totalPages="10" :perPage="10" :currentPage="currentPage"
                    @pagechanged="onPageChange" />
            </div>
            <div v-else>
                <ApiDataTable_Component :data="ApiCalls"></ApiDataTable_Component>
                <Pageinator_Component :totalPages="10" :perPage="10" :currentPage="currentPage"
                    @pagechanged="onPageChange" />
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import vuexstore from '@/vuexstore';
import Dashboard_Component from './NavBar/Dashboard_Component.vue';
import PieChart_Component from './Dashboard/PieChart_Component.vue';
import DataTable_Component from './Dashboard/DataTable_Component.vue';
import Statistics_component from './Dashboard/Statistics_component.vue'
import Pageinator_Component from './Dashboard/Pageinator_Component.vue'
import ApiDataTable_Component from './Dashboard/ApiDataTable_Component.vue';
import DataRangeCombobox from './Dashboard/DataRangeCombobox.vue';

export default {

    data() {
        return {
            menuItems: [{
                link: "#",
                name: "Betting Company",
                tooltip: "Client Database",
                icon: "bx-pie-chart-alt-2",
                filterTerm: "CE76C462-DE73-46F0-9A94-D5EBB9FC3C03",
                authlevel: 1,
                DataorApi: false
            },
            {
                link: "#",
                name: "SupaBets",
                tooltip: "API Call-Log",
                icon: "bx-pie-chart-alt-2",
                filterTerm: "83945290-2C00-45C2-B2A5-1449EF1B9E1D",
                authlevel: 1,
                DataorApi: false
            },
            ],
            customers: [],
            ApiCalls: [{
                UniqueID: "F12345678",
                ID: "9502052335846",
                WhoYouCall: "Yes",
                DOWhoYouCall: "2022/11/30 10:39:56",
                VerificationStatus: "Verified",
                CPBcall: "No",
                DOCPBcall: "2022/11/30 10:39:56",
                CPBStatus: "n/a",
            },
            {
                UniqueID: "F12344589",
                ID: "0102052335846",
                WhoYouCall: "No",
                DOWhoYouCall: "2022/11/30 10:39:56",
                VerificationStatus: "Not Verified",
                CPBcall: "Yes",
                DOCPBcall: "2022/11/30 10:39:56",
                CPBStatus: "Verified",
            },],
            NationalityInfo: vuexstore.state.Nationality,
            StatusInfo: vuexstore.state.StatusInfo,
            SOFInfo: vuexstore.state.SoFInfo,
            MarketingInfo: vuexstore.state.Marketing,
            filterTerm: vuexstore.getters.guid,
            titleMarketing: 'Signed up for marketing',
            titleSOF: 'Source of funds typing ',
            WhoYouTitle: 'WhoYou API Call',
            CPBTitle: 'CPB API Call',
            NationalityTitle: 'Nationality',
            StatusTitle: 'Verification Staus',
            currentPage: 1,
            noOfPages: 0,
            todaystats: 0,
            weeklystats: 0,
            DataorApi: vuexstore.getters.viewType,
            ClientInfo: vuexstore.state.ClientInfo,
            isOpened: true,
        }
    },
    components:
    {
        Dashboard_Component,
        PieChart_Component,
        DataTable_Component,
        Statistics_component,
        Pageinator_Component,
        ApiDataTable_Component,
        DataRangeCombobox
    },
    methods: {
        updateIsOpen(checkOpen){
            this.isOpened = checkOpen;
        },
        changeRange() {
            this.refreshPage()
        },
        onPageChange(page) {
            this.currentPage = page;
            this.refreshPage()
        },
        setdoa(event) {
            this.DataorApi = event;
            this.refreshPage
        },
        setCompany(event) {
            this.filterTerm = event;
            this.refreshPage
        },
        refreshPage() {
            axios
                .post(`Customer/GetCustomerDetailsPagination`,
                    {
                        guid: vuexstore.getters.guid,
                        pageNum: this.currentPage,
                        sortingCol: "",
                        sortType: "",
                        dateRange: vuexstore.getters.DateRange
                    }
                )
                .then(response => {
                    this.customers = response.data;
                });
            axios
                .get(`Customer/GetNationality?Guid=${vuexstore.getters.guid}`)
                .then(response => {
                    const infomation = response.data.map(data => {
                        return {
                            name: data.nationality,
                            amount: parseInt(data.tally),
                        };
                    });

                    vuexstore.commit('Nationality', infomation);
                    this.NationalityInfo = vuexstore.state.Nationality;
                });
            axios
                .get(`Customer/GetStatus?Guid=${vuexstore.getters.guid}`)
                .then(response => {
                    const infomation = response.data.map(data => {
                        return {
                            name: data.status,
                            amount: parseInt(data.tally),
                        };
                    });

                    vuexstore.commit('StatusInfo', infomation);
                    this.StatusInfo = vuexstore.state.StatusInfo;
                });
            axios
                .get(`Customer/GetMarketing?Guid=${vuexstore.getters.guid}`)
                .then(response => {
                    const infomation = response.data.map(data => {
                        return {
                            name: data.marketing,
                            amount: parseInt(data.tally),
                        };
                    });

                    vuexstore.commit('Marketing', infomation);
                    this.MarketingInfo = vuexstore.state.Marketing;
                });
            axios
                .get(`Customer/GetSourceOfFunds?Guid=${vuexstore.getters.guid}`)
                .then(response => {
                    const infomation = response.data.map(data => {
                        return {
                            name: data.sourceOfFunds,
                            amount: parseInt(data.tally),
                        };
                    });

                    vuexstore.commit('SoFInfo', infomation);
                    this.SOFInfo = vuexstore.state.SoFInfo;
                });

            axios
                .get(`Customer/GetOnboardedCustomers?Guid=${vuexstore.getters.guid}`)
                .then(response => {
                    const infomation = response.data.map(data => {
                        return {
                            daily: parseInt(data.daily),
                            Weekly: parseInt(data.weekly),
                        };
                    });

                    vuexstore.commit('Onboarding', infomation);
                    this.todaystats = vuexstore.state.Onboarding[0].daily;
                    this.weeklystats = vuexstore.state.Onboarding[0].weekly;
                });

            axios
                .get(`Client/GetClientInformation`)
                .then(response => {
                    const infomation = response.data.map(data => {
                        return {
                            companyID: data.companyID,
                            companyName: data.companyName,
                            guid: data.guid,
                        };
                    });

                    vuexstore.commit('ClientInfo', infomation);
                    this.ClientInfo = vuexstore.state.ClientInfo;
                });
        }
    },
    mounted() {
        axios
            .post(`Customer/GetCustomerDetailsPagination`,
                {
                    guid: vuexstore.getters.guid,
                    pageNum: this.currentPage,
                    sortingCol: "",
                    sortType: "",
                    dateRange: vuexstore.getters.DateRange
                }
            )
            .then(response => {
                this.customers = response.data;
            });
        axios
            .get(`Customer/GetNationality?Guid=${vuexstore.getters.guid}`)
            .then(response => {
                const infomation = response.data.map(data => {
                    return {
                        name: data.nationality,
                        amount: parseInt(data.tally),
                    };
                });

                vuexstore.commit('Nationality', infomation);
                this.NationalityInfo = vuexstore.state.Nationality;
            });
        axios
            .get(`Customer/GetStatus?Guid=${vuexstore.getters.guid}`)
            .then(response => {
                const infomation = response.data.map(data => {
                    return {
                        name: data.status,
                        amount: parseInt(data.tally),
                    };
                });

                vuexstore.commit('StatusInfo', infomation);
                this.StatusInfo = vuexstore.state.StatusInfo;
            });
        axios
            .get(`Customer/GetMarketing?Guid=${vuexstore.getters.guid}`)
            .then(response => {
                const infomation = response.data.map(data => {
                    return {
                        name: data.marketing,
                        amount: parseInt(data.tally),
                    };
                });

                vuexstore.commit('Marketing', infomation);
                this.MarketingInfo = vuexstore.state.Marketing;
            });
        axios
            .get(`Customer/GetSourceOfFunds?Guid=${vuexstore.getters.guid}`)
            .then(response => {
                const infomation = response.data.map(data => {
                    return {
                        name: data.sourceOfFunds,
                        amount: parseInt(data.tally),
                    };
                });

                vuexstore.commit('SoFInfo', infomation);
                this.SOFInfo = vuexstore.state.SoFInfo;
            });

        axios
            .get(`Customer/GetOnboardedCustomers?Guid=${vuexstore.getters.guid}`)
            .then(response => {
                const infomation = response.data.map(data => {
                    return {
                        daily: parseInt(data.daily),
                        Weekly: parseInt(data.weekly),
                    };
                });

                vuexstore.commit('Onboarding', infomation);
                this.todaystats = vuexstore.state.Onboarding[0].daily;
                this.weeklystats = vuexstore.state.Onboarding[0].weekly;
            });

        axios
            .get(`Client/GetClientInformation`)
            .then(response => {
                const infomation = response.data.map(data => {
                    return {
                        companyID: data.companyID,
                        companyName: data.companyName,
                        guid: data.guid,
                    };
                });

                vuexstore.commit('ClientInfo', infomation);
                this.ClientInfo = vuexstore.state.ClientInfo;
            });
    },
}
</script>

<style>
.containerCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 95%;
}

.dashboard-body-open {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 79%;
}

.dashboard-body-closed {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 94%;
}
</style>