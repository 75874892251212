<template>
    <v-combobox :label="title" :items="dateRanges" variant="solo" v-model="selected" class="dateRange"
        @update:model-value="DateRangeChange">
    </v-combobox>
</template>

<script>
import vuexstore from '@/vuexstore';
import axios from 'axios';

export default {
    data() {
        return {
            selected: vuexstore.getters.DateRange,
            dateRanges: [],
        }
    },
    props: {
        title: {
            type: String,
            default: ""
        }
    },
    mounted() {
        axios.get(`Customer/GetDateRange`)
            .catch(error => {
                console.log(error)
            }).then(response => {
                this.dateRanges = response.data.map(
                    data => {
                        return data.dateRange;
                    });
            });
    },
    methods: {
        DateRangeChange() {
            vuexstore.commit("DateRange", this.selected);
        }
    }
}
</script>

<style>
/* .v-combobox #dateRange {
    background-color: rgba(165, 42, 42, 0);
    color: aqua;
}

.v-combobox--single.v-text-field input #dateRange {
    background-color: rgba(63, 10, 10, 0);
    color: rgb(0, 0, 0);
}

.v-combobox .v-field:not(.v-field--focused) input #dateRange {
    color: rgb(0, 0, 0);
}

.v-input--density-default .v-field--variant-solo #dateRange,
.v-input--density-default .v-field--variant-filled #dateRange {

    background-color: rgba(0, 0, 0, 0.795);
}

.v-field--variant-solo #dateRange {} */
</style>
